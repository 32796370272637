import * as React from "react"
import Header from "./Header"
import Footer from "./Footer"

/**
 * This component is for the rendering of header, main and footer.
 *
 * @version 1.0.1
 * @visibleName Layout
 * @author [Dylan Lesperance]
 */

function HumanLayout(props) {
  return (
    //Footer will always stay at the bottom with this styling
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="bg-gray flex-grow">{props.children}</main>
      <Footer />
    </div>
  )
}

export default HumanLayout
