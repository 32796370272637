import * as React from "react"
import { useMsal, useIsAuthenticated } from "@azure/msal-react"
import { Link } from "gatsby"

/**
 * This component is for the footer at the bottom of the website
 *
 * @version 1.0.1
 * @visibleName Footer
 * @author [Dylan Lesperance]
 */

export default function Footer() {
  const { instance, accounts, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  return (
    <>
      <footer className="bg-blue px-8 py-8 lg:pb-8 lg:pt-16 lg:px-32">
        <div className="flex flex-col lg:flex lg:flex-row lg:space-x-10 lg:border-white lg:border-t-2 space-y-8 lg:space-y-0  items-start justify-start text-lg font-sans text-gray mb-6">
          {/* Logo that works as a go back to mainsite button */}
          <Link to="/">
            <svg
              className="text-white mb-2 mt-4 hover:text-orange-dark hover:cursor-pointer"
              width="124"
              height="51"
              fill="currentColor"
              viewBox="0 0 124 51"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0)">
                <path d="M13.1897 25.0224C13.1062 20.4486 13.015 15.2557 12.6657 7.38519C12.6657 6.69641 12.5214 6.34815 12.3239 6.34815C12.1265 6.34815 11.9746 6.87441 11.9746 7.38519C11.724 21.1993 8.93688 24.1014 6.65858 24.1014C3.43859 24.1014 1.70709 18.5061 1.49445 7.46257C1.49445 7.46257 1.49445 6.35589 1.49445 5.10217V4.26637C1.49445 1.60415 1.49445 1.06242 1.49445 1.05468C1.49445 0.543905 1.38813 0.0253947 1.12992 0.0176557C0.871716 0.00991672 0.780582 0.5826 0.7578 1.05468C0.7578 1.05468 0.529971 7.42387 0.529971 7.46257C0.195821 14.9771 0.119877 20.0152 0.0515279 24.4884V24.5735C0.0515279 26.0207 0.0059632 27.3828 -0.0244141 28.7216C-0.0244141 29.8438 0.0211519 30.9891 0.0439349 32.1964V32.3589C0.127472 36.9404 0.218603 42.1333 0.567942 50.0193C0.567942 50.7081 0.712233 51.0486 0.917279 51.0486C1.12233 51.0486 1.25902 50.5301 1.25902 50.0116C1.50964 36.1975 4.29675 33.3031 6.57505 33.3031C8.85334 33.3031 11.6708 36.1975 11.8911 50.0116C11.8911 50.5301 11.9974 51.0486 12.2404 51.0486C12.4379 51.0486 12.5518 50.7081 12.5822 50.0193C12.9315 42.141 13.0226 36.9559 13.1062 32.3744C13.1062 31.0975 13.1517 29.9057 13.1745 28.7216C13.1973 27.5375 13.2125 26.2916 13.1897 25.0224ZM6.60542 21.0987C8.04834 21.0987 9.21787 19.5509 9.21787 17.6161C9.21787 15.6814 8.04834 14.1336 6.60542 14.1336C5.1625 14.1336 3.98539 15.6814 3.98539 17.6161C3.98539 19.5509 5.19288 21.0987 6.60542 21.0987Z"></path>
                <path d="M32.6091 30.8941H30.8169V25.0821H24.4528V30.9251H22.6758V17.4824H24.4528V23.395H30.8169V17.4824H32.6091V30.8941Z"></path>
                <path d="M40.7058 21.8007H42.4449V30.894H40.7058V29.532C40.422 30.0192 40.0151 30.4198 39.5275 30.692C39.0399 30.9643 38.4896 31.0982 37.9339 31.0798C35.7012 31.0798 34.4785 29.6016 34.5013 27.0477V21.793H36.2252V26.9317C36.2252 28.4795 36.9846 29.3462 38.1769 29.3462C38.5211 29.3718 38.8665 29.3203 39.189 29.1952C39.5115 29.0701 39.8031 28.8745 40.0435 28.6222C40.2838 28.3699 40.467 28.067 40.5801 27.7349C40.6932 27.4027 40.7336 27.0493 40.6982 26.6995L40.7058 21.8007Z"></path>
                <path d="M52.3173 25.9884V30.8949H50.5782V25.733C50.5782 24.2394 49.8187 23.3417 48.6492 23.3417C48.3081 23.322 47.9669 23.3779 47.6492 23.5056C47.3314 23.6333 47.0445 23.8297 46.8085 24.0813C46.5724 24.333 46.3927 24.6338 46.2817 24.963C46.1708 25.2922 46.1313 25.6421 46.1659 25.9884V30.8949H44.4268V21.8016H46.1659V23.1637C46.424 22.7234 46.7824 22.353 47.2107 22.0836C47.6389 21.8142 48.1245 21.6538 48.6264 21.6159C48.9542 21.5967 49.2831 21.6175 49.6061 21.6778C50.1154 21.7316 50.6009 21.9247 51.0113 22.2367C51.4217 22.5487 51.7418 22.9679 51.9375 23.45C52.2187 22.8657 52.6606 22.3775 53.2095 22.0449C53.7584 21.7123 54.3904 21.5498 55.0284 21.5772C57.2611 21.5772 58.4686 23.0553 58.4686 25.6092V30.864H56.7295V25.7253C56.7295 24.2239 55.9701 23.3107 54.6867 23.3107C53.2134 23.3185 52.3173 24.4716 52.3173 25.9884Z"></path>
                <path d="M60.5271 22.8765C61.0189 22.3976 61.6008 22.0252 62.2378 21.7818C62.8747 21.5383 63.5535 21.429 64.2331 21.4603C66.7696 21.4603 68.3036 22.9152 68.3036 25.4227V30.8941H66.6025V29.7256C66.2136 30.1759 65.7308 30.5319 65.1894 30.7676C64.6481 31.0033 64.0618 31.1126 63.4737 31.0876C61.446 31.0876 60.1094 29.741 60.1094 27.992C60.1094 26.4442 61.1422 25.175 63.9065 25.175H66.6025C66.6025 23.8517 65.7064 23.0778 64.1192 23.0778C63.2105 23.0551 62.3239 23.3654 61.6206 23.9523L60.5271 22.8765ZM66.5645 27.9766V26.63H63.9065C62.0763 26.63 61.7346 27.4039 61.7346 28.0075C61.7449 28.2284 61.7991 28.4448 61.8939 28.6437C61.9888 28.8425 62.1222 29.0197 62.2863 29.1644C62.4503 29.3091 62.6415 29.4184 62.8482 29.4856C63.0549 29.5528 63.2729 29.5765 63.4888 29.5553C64.0921 29.5541 64.6869 29.411 65.227 29.1372C65.767 28.8634 66.2378 28.4662 66.6025 27.9766H66.5645Z"></path>
                <path d="M72.0399 30.8939H70.3008V21.8006H72.0399V23.1627C72.3274 22.6754 72.7372 22.275 73.2271 22.003C73.7171 21.7309 74.2693 21.5969 74.827 21.6149C77.0445 21.6149 78.2672 23.093 78.2672 25.6469V30.9017H76.5281V25.763C76.5281 24.2152 75.7687 23.3484 74.5612 23.3484C74.2174 23.3242 73.8725 23.3767 73.5507 23.5022C73.2288 23.6278 72.9377 23.8234 72.6977 24.0754C72.4576 24.3274 72.2744 24.6297 72.1608 24.9613C72.0472 25.2928 72.0059 25.6457 72.0399 25.9951V30.8939Z"></path>
                <path d="M84.6226 25.1523V26.8472H79.9521V25.1523H84.6226Z"></path>
                <path d="M94.2068 17.4824V19.1773H88.1845V23.4569H93.4398V25.1518H88.1845V29.1993H94.3967V30.8941H86.415V17.4824H94.2068Z"></path>
                <path d="M107.369 23.9213C107.498 22.5979 108.888 21.5996 110.46 21.5996C112.032 21.5996 113.399 22.3193 113.558 24.169H111.895C111.829 23.8512 111.649 23.5698 111.391 23.3796C111.133 23.1893 110.814 23.1038 110.498 23.1397C110.219 23.1148 109.94 23.1798 109.7 23.3257C109.46 23.4716 109.272 23.6908 109.161 23.9523C109.072 24.1863 109.072 24.4457 109.161 24.6797C109.279 24.8631 109.433 25.0196 109.614 25.1394C109.794 25.2593 109.997 25.3399 110.209 25.3762L111.523 25.709C113.376 26.1734 113.801 27.3265 113.801 28.3558C113.801 30.0506 112.191 31.0799 110.338 31.0799C109.532 31.119 108.743 30.8386 108.135 30.2975C107.528 29.7565 107.15 28.9968 107.08 28.1778H108.667C108.704 28.3987 108.792 28.6075 108.924 28.7866C109.056 28.9657 109.229 29.1099 109.427 29.207C109.854 29.4576 110.348 29.5658 110.839 29.5166C111.172 29.5382 111.501 29.4266 111.755 29.2055C112.008 28.9845 112.168 28.6713 112.199 28.3325C112.199 27.667 111.659 27.342 111.014 27.1794L109.791 26.8931C108.128 26.4365 107.217 25.6007 107.369 23.9213Z"></path>
                <path d="M117.643 30.8941H115.965V17.4824H117.681V24.9738L120.719 21.8008H123.088L119.291 25.7322L124.03 30.8941H121.752L117.681 26.4519L117.643 30.8941Z"></path>
                <path d="M104.21 19.6029C104.43 19.5893 104.642 19.5101 104.819 19.3751C104.996 19.2401 105.131 19.0551 105.206 18.8432C105.281 18.6312 105.293 18.4015 105.242 18.1824C105.19 17.9633 105.076 17.7644 104.915 17.6104C104.754 17.4563 104.551 17.3538 104.333 17.3156C104.116 17.2773 103.891 17.305 103.689 17.3951C103.486 17.4852 103.314 17.6339 103.193 17.8227C103.072 18.0115 103.009 18.2322 103.01 18.4576C103.014 18.6134 103.048 18.7669 103.111 18.9091C103.173 19.0514 103.263 19.1795 103.375 19.2861C103.486 19.3926 103.618 19.4755 103.761 19.5299C103.904 19.5843 104.057 19.6092 104.21 19.6029Z"></path>
                <path d="M102.243 21.8008V23.3021H103.352V30.8941H105.068V21.8008H102.243Z"></path>
                <path d="M100.016 29.2389C98.5582 29.2389 98.3912 28.0162 98.4064 26.9792V23.3031H100.533V21.8018H98.4064V18.8223L96.6521 19.3717V21.8018H95.5889V23.3031H96.6521V27.0024C96.6521 29.2622 97.4571 30.8719 99.8569 30.8719H101.535V29.2157L100.016 29.2389Z"></path>
                <path d="M30.3536 37.1328V38.8277H24.4528V43.1073H29.131V44.8022H24.4528V50.5445H22.6758V37.1328H30.3536Z"></path>
                <path d="M30.1484 45.9782C30.1484 44.7159 30.6405 43.5054 31.5164 42.6128C32.3923 41.7202 33.5802 41.2188 34.8189 41.2188C36.0576 41.2188 37.2456 41.7202 38.1215 42.6128C38.9974 43.5054 39.4894 44.7159 39.4894 45.9782C39.4894 47.2405 38.9974 48.4511 38.1215 49.3437C37.2456 50.2363 36.0576 50.7377 34.8189 50.7377C33.5802 50.7377 32.3923 50.2363 31.5164 49.3437C30.6405 48.4511 30.1484 47.2405 30.1484 45.9782ZM37.7428 45.9782C37.7044 45.2092 37.3776 44.4846 36.83 43.9542C36.2823 43.4237 35.5556 43.1279 34.8 43.1279C34.0444 43.1279 33.3176 43.4237 32.77 43.9542C32.2223 44.4846 31.8955 45.2092 31.8572 45.9782C31.8369 46.3849 31.898 46.7915 32.0367 47.1733C32.1754 47.5551 32.3889 47.9042 32.6642 48.1993C32.9394 48.4944 33.2707 48.7294 33.6379 48.89C34.005 49.0505 34.4004 49.1333 34.8 49.1333C35.1995 49.1333 35.5949 49.0505 35.962 48.89C36.3292 48.7294 36.6605 48.4944 36.9357 48.1993C37.211 47.9042 37.4245 47.5551 37.5632 47.1733C37.7019 46.7915 37.763 46.3849 37.7428 45.9782Z"></path>
                <path d="M42.6649 50.5428H40.9258V41.4495H42.6649V43.4926C43.1205 41.8984 43.9863 41.2561 45.1482 41.2561C45.6742 41.2504 46.1942 41.3696 46.6671 41.6043L46.4013 43.2604C45.9883 43.0601 45.5373 42.9544 45.0799 42.9509C43.3636 42.9509 42.6345 44.816 42.6345 47.2306L42.6649 50.5428Z"></path>
                <path d="M49.6737 50.5445H47.9346V37.1328H49.6737V42.8132C50.0203 42.3026 50.4905 41.8918 51.0386 41.6206C51.5868 41.3495 52.1944 41.2271 52.8025 41.2654C53.4096 41.2519 54.0129 41.366 54.5748 41.6005C55.1366 41.8351 55.6451 42.1852 56.0683 42.6288C56.4916 43.0725 56.8206 43.6003 57.0348 44.1793C57.2491 44.7582 57.344 45.376 57.3136 45.994C57.3396 46.6159 57.2404 47.2366 57.022 47.818C56.8036 48.3993 56.4707 48.9289 56.0438 49.3741C55.6168 49.8193 55.105 50.1707 54.5397 50.4066C53.9744 50.6425 53.3677 50.7579 52.757 50.7457C52.1481 50.7696 51.5432 50.6359 50.9987 50.3571C50.4542 50.0784 49.9879 49.6636 49.6433 49.1515L49.6737 50.5445ZM52.6506 48.9967C53.0386 49.0013 53.4234 48.9252 53.7816 48.7731C54.1398 48.6211 54.4638 48.3963 54.7339 48.1124C55.0039 47.8285 55.2144 47.4915 55.3524 47.1219C55.4904 46.7524 55.553 46.3581 55.5365 45.963C55.5545 45.5701 55.4925 45.1777 55.3544 44.8105C55.2163 44.4432 55.005 44.1091 54.7339 43.8292C54.4627 43.5493 54.1377 43.3297 53.7791 43.1841C53.4206 43.0385 53.0364 42.9702 52.6506 42.9835C52.2506 42.9628 51.8506 43.0252 51.475 43.167C51.0993 43.3087 50.7559 43.5269 50.4656 43.8081C50.1753 44.0893 49.9441 44.4278 49.7861 44.8029C49.6282 45.178 49.5467 45.5819 49.5467 45.9901C49.5467 46.3983 49.6282 46.8022 49.7861 47.1773C49.9441 47.5524 50.1753 47.8909 50.4656 48.1721C50.7559 48.4533 51.0993 48.6714 51.475 48.8132C51.8506 48.955 52.2506 49.0174 52.6506 48.9967Z"></path>
                <path d="M65.159 41.4491H66.8981V50.5425H65.159V49.1804C64.8752 49.6676 64.4682 50.0682 63.9807 50.3405C63.4931 50.6127 62.9427 50.7466 62.387 50.7282C60.1543 50.7282 58.9316 49.25 58.9544 46.7039V41.4414H60.6935V46.5801C60.6935 48.1279 61.415 48.9947 62.6377 48.9947C62.9818 49.0202 63.3272 48.9687 63.6497 48.8436C63.9722 48.7185 64.2639 48.523 64.5042 48.2707C64.7445 48.0184 64.9277 47.7155 65.0408 47.3833C65.154 47.0511 65.1943 46.6977 65.159 46.3479V41.4491Z"></path>
                <path d="M70.619 50.544H68.8799V41.4507H70.619V42.8128C70.9076 42.3266 71.3177 41.9272 71.8074 41.6552C72.297 41.3833 72.8487 41.2487 73.4061 41.265C75.6237 41.265 76.8463 42.7431 76.8463 45.297V50.5518H75.1072V45.4131C75.1072 43.8653 74.3478 42.9985 73.1403 42.9985C72.7965 42.9743 72.4516 43.0268 72.1298 43.1524C71.8079 43.2779 71.5168 43.4735 71.2768 43.7255C71.0367 43.9775 70.8535 44.2798 70.7399 44.6114C70.6263 44.943 70.585 45.2958 70.619 45.6453V50.544Z"></path>
                <path d="M85.8774 49.1437C85.5289 49.6554 85.0597 50.0697 84.5129 50.3483C83.9662 50.6268 83.3595 50.7608 82.7486 50.7379C81.5474 50.6884 80.4116 50.1674 79.579 49.2839C78.7463 48.4003 78.2812 47.2228 78.2812 45.9978C78.2812 44.7727 78.7463 43.5952 79.579 42.7117C80.4116 41.8281 81.5474 41.3071 82.7486 41.2576C83.3603 41.2167 83.972 41.3378 84.5242 41.609C85.0765 41.8802 85.5505 42.2924 85.9002 42.8054V37.125H87.6393V50.5444H85.9002L85.8774 49.1437ZM82.9005 42.9912C82.5147 42.9779 82.1305 43.0462 81.772 43.1918C81.4134 43.3373 81.0884 43.557 80.8173 43.8369C80.5461 44.1168 80.3349 44.4509 80.1967 44.8182C80.0586 45.1854 79.9966 45.5778 80.0146 45.9707C80.0147 46.5616 80.1872 47.1392 80.5101 47.63C80.8331 48.1207 81.292 48.5026 81.8285 48.7269C82.365 48.9512 82.9548 49.0079 83.523 48.8898C84.0911 48.7717 84.612 48.484 85.0194 48.0634C85.4267 47.6429 85.7021 47.1084 85.8106 46.5279C85.9191 45.9474 85.8558 45.3471 85.6286 44.8034C85.4015 44.2597 85.0209 43.7971 84.5351 43.4744C84.0493 43.1517 83.4803 42.9835 82.9005 42.9912Z"></path>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="124" height="51" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
          </Link>
          {/* Links to other websites and information */}
          <div className="flex flex-col lg:flex lg:flex-row lg:justify-around border-t-2 border-white w-full lg:border-t-0">
            <div className="mb-4 mt-4 flex flex-col">
              <div>
                <a
                  href={"https://human.no/"}
                  target="_blank"
                  className="font-sans text-base mb-2 hover:text-orange-dark w-auto"
                >
                  Human.no
                </a>
              </div>
              <div>
                <a
                  href={"https://humanistiskungdom.no/"}
                  target="_blank"
                  className="font-sans text-base hover:text-orange-dark w-auto"
                >
                  Humanistisk ungdom
                </a>
              </div>
              <div>
                {/* Checks if the user is authenticated. If the user is authenticated the button will say "Logg ut" or else "Logg inn" */}
                {isAuthenticated ? (
                  <button
                    onClick={() => instance.logout()}
                    className=" invisible lg:text-sans lg:text-base lg:border-white lg:px-3 lg:py-2 lg:border-2 lg:mt-12 lg:visible lg:flex-none lg:w-32 lg:text-white lg:hover:text-blue lg:hover:bg-100 lg:bg-x-0 lg:bg-left transition-all duration-100 bg-no-repeat to-white from-white bg-gradient-to-t "
                  >
                    Logg ut
                  </button>
                ) : (
                  <button
                    onClick={() => instance.loginRedirect()}
                    className=" invisible lg:text-sans lg:text-base lg:border-white lg:px-3 lg:py-2 lg:border-2 lg:mt-12 lg:visible lg:flex-none lg:w-32 lg:text-white lg:hover:text-blue lg:hover:bg-100 lg:bg-x-0 lg:bg-left transition-all duration-100 bg-no-repeat to-white from-white bg-gradient-to-t "
                  >
                    Logg inn
                  </button>
                )}
              </div>
            </div>
            <div className="lg:mb-4 lg:mt-4 flex flex-col">
              <div>
                <a
                  href={"https://www.human.no/om-oss/personvernerkl%C3%A6ring"}
                  target="_blank"
                  className="font-sans text-base mb-2 hover:text-orange-dark w-auto"
                >
                  Personvern
                </a>
              </div>
              <div>
                <a
                  href={"https://human.no/om-oss/bruk-av-informasjonskapsler/"}
                  target="_blank"
                  className="font-sans text-base mb-2 hover:text-orange-dark w-auto"
                >
                  Informasjonskapsler
                </a>
              </div>
            </div>
          </div>
          {/* Checks if the user is authenticated. If the user is authenticated the button will say "Logg ut" or else "Logg inn" */}
          {isAuthenticated ? (
            <button
              onClick={() => instance.logout()}
              className="border-2 border-white p-1 px-3 pt-1 pb-2 mr-4 font-sans text-base text-white hover:text-blue hover:bg-100 transition-all duration-100 bg-no-repeat to-white from-white bg-gradient-to-t bg-x-0 bg-left lg:invisible"
            >
              Logg ut
            </button>
          ) : (
            <button
              onClick={() => instance.loginRedirect()}
              className="border-2 border-white p-1 px-3 pt-1 pb-2 mr-4 font-sans text-base text-white hover:text-blue hover:bg-100 transition-all duration-100 bg-no-repeat to-white from-white bg-gradient-to-t bg-x-0 bg-left lg:invisible"
            >
              Logg inn
            </button>
          )}
        </div>
      </footer>
    </>
  )
}
