// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-my-page-home-tsx": () => import("./../../../src/components/MyPage/Home.tsx" /* webpackChunkName: "component---src-components-my-page-home-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activities-js": () => import("./../../../src/pages/activities.js" /* webpackChunkName: "component---src-pages-activities-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-hefinn-tsx": () => import("./../../../src/pages/hefinn.tsx" /* webpackChunkName: "component---src-pages-hefinn-tsx" */),
  "component---src-pages-huinn-tsx": () => import("./../../../src/pages/huinn.tsx" /* webpackChunkName: "component---src-pages-huinn-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-verifisering-js": () => import("./../../../src/pages/verifisering.js" /* webpackChunkName: "component---src-pages-verifisering-js" */)
}

